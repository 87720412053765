import {Menu, Tabs, Tooltip} from 'antd';
import React from 'react';
import AdminProgress from "@/components/progress";
import {FullContent} from "@/components/content/full-content";
import {RouterCodeMap, RouterPathMap} from "@/config/router-parse";
import Global from "@/config/global";

export  type TabsContentProps = { code: string | number, onChange: (key: string) => void }


export class TabsContent extends React.Component<TabsContentProps, any> {
    state = {
        content: [], activeKey: "",
    }

    constructor(props) {
        super(props);
        if (props.code) {
            this.state = {content: [this.toLabel(props)], activeKey: props.code}
        }
    }

    toLabel(item: TabsContentProps) {
        let element = RouterCodeMap[item.code];
        let Comp = React.lazy(() => import(`@/${element.element}`));
        return {
            label: <Tooltip zIndex={1000} getPopupContainer={() => document.body} trigger={["hover", "contextMenu"]}
                            mouseEnterDelay={5} style={{padding: 0, margin: 0}}
                            title={<>
                                <Menu
                                    items={[{
                                        label: "关闭其他", key: "2", onClick: () => {
                                            let {content} = this.state
                                            for (let i in content) {
                                                if (content[i].code === item.code) {
                                                    this.doRefresh({
                                                        activeKey: content[i].code, content: [content[i]]
                                                    })
                                                    break
                                                }
                                            }
                                        }
                                    }, {
                                        label: "关闭左边", key: "3", onClick: () => {
                                            let {activeKey, content} = this.state
                                            let newContent = []
                                            let flag = false
                                            let newKey = false
                                            for (let i in content) {
                                                if (flag || (flag = content[i].code === item.code)) {
                                                    newContent.push(content[i])
                                                } else if (activeKey === content[i].code) {
                                                    newKey = true;
                                                }
                                            }
                                            if (newKey) {
                                                activeKey = content[0].code;
                                            }
                                            this.doRefresh({activeKey: activeKey, content: newContent})
                                        }
                                    }, {
                                        label: "关闭右边", key: "4", onClick: () => {
                                            let {activeKey, content} = this.state
                                            let newContent = []
                                            let newKey = true
                                            for (let i in content) {
                                                newContent.push(content[i])
                                                if (activeKey === content[i].code) {
                                                    newKey = false;
                                                }
                                                if (content[i].code === item.code) {
                                                    break
                                                }
                                            }
                                            if (newKey) {
                                                activeKey = newContent[0].code
                                            }
                                            this.doRefresh({activeKey: activeKey, content: newContent})

                                        }
                                    },]}
                                />
                            </>}
                            placement="bottomLeft"
                            color={"white"}
            >{element.name}</Tooltip>, children: <>
                <React.Suspense fallback={<><AdminProgress/></>}>
                    <FullContent
                        style={{padding: '10px 24px 24px 24px', minHeight: '50vh', backgroundColor: 'white'}}>
                        <Comp/>
                    </FullContent>
                </React.Suspense>
            </>, code: item.code,
        }
    }

    doRefresh(state) {
        this.setState(state)
        if (state.activeKey) {
            localStorage.setItem("last_tabs", state.activeKey)
        }

    }

    switchTabs(item: TabsContentProps) {
        let {content} = this.state
        for (let i in content) {
            if (content[i].code === item.code) {
                this.doRefresh({activeKey: item.code})
                return false
            }
        }


        if (item.code === RouterPathMap[Global.HomePage]?.code) {
            const lastTabs = localStorage.getItem("last_tabs")
            if (lastTabs && lastTabs!== RouterPathMap[Global.HomePage]?.code ) {
                this.doRefresh({
                    content: [...content, this.toLabel(item), this.toLabel({code: lastTabs})], activeKey: lastTabs
                })

                return true;
            }

        }

        this.doRefresh({
            content: [...content, this.toLabel(item)], activeKey: item.code
        })
        return true;
    }

    componentDidUpdate(prevProps: Readonly<TabsContentProps>, prevState: Readonly<TabsContentProps>, snapshot) {
        if (prevProps.code !== this.props.code) {
            this.switchTabs(this.props)
        }
    }

    remove(code: string, currentCode: string) {
        let newV = []
        let newKey = ""
        let {content} = this.state
        for (let i in content) {
            if (content[i].code !== code) {
                newV.push(content[i])
            }
        }
        if (code === currentCode) {
            if (newV.length > 0) {
                newKey = newV[newV.length - 1].code
            } else {
                newKey = undefined
            }
        } else {
            newKey = currentCode;
        }
        this.doRefresh({activeKey: newKey, content: newV})
    }


    render() {
        const {activeKey, content} = this.state
        const {onChange} = this.props;
        return <>
            <Tabs
                hideAdd
                tabBarStyle={{margin: "0px"}}
                onTabClick={(key, e) => {
                    if (!e.target.className.includes("ant-menu-title-content") && !e.target.className.includes("ant-menu-item")) {
                        this.doRefresh({activeKey: key, content: content})
                        onChange?.(key)
                    }
                }}
                animated={{inkBar: true, tabPane: true}}
                activeKey={activeKey}
                type="editable-card"
                onEdit={(targetCode: string, action: 'add' | 'remove') => {
                    if (action === 'remove') {
                        this.remove(targetCode, activeKey);
                    }
                }}
                items={content.map(p => {
                    return {...p, key: p.code}
                })}
            />
        </>;
    }
}


