import {Copy, IsFalse, IsTrue, NotNull} from '@/config/util';
import {CopyElement, loadMenuElementMapping, MenuElement, RouterCodeMap} from '@/config/router-parse';
import md5 from "md5"
import Global, {ROUTER_DATA} from "../config/global";

export const SECRET = '*32d212fasdxuhnoasdfa3%#452#%$#^@%^#%^#&#@$%@34343';


export class LoginUser {
    username: string = '未登录';
    uuid: string = '';
    userNo: string = '';
    avatar: string = '';
    token: string = '';
    roles: string[] = [];
    codeMapping: {} = {};
    pathMapping: {} = {};
    menu: MenuElement[] = [];
    button: MenuElement[] = [];
    login: boolean = false;
    initPwd: boolean = true;


    with(data: LoginUser): LoginUser {
        const {
            username = '未登录',
            avatar = '',
            token = '',
            userNo = '',
            uuid = '',
            roles = [],
            menu = [],
            button = [],
            initPwd = true
        } = data;
        this.username = username;
        this.avatar = avatar;
        this.token = token;
        this.uuid = uuid;
        this.userNo = userNo;
        this.roles = roles;
        this.menu = menu;
        this.button = button;
        this.initPwd = initPwd;
        this.login = true;
        return this;
    };

    refresh() {
        if (IsFalse(this.login)) {
            this.reload();
        }
    };

    reload() {
        const obj = localStorage.getItem(Global.USERINFO_KEY);
        const secret = localStorage.getItem(Global.SECRET_KEY);
        if (NotNull(obj)) {
            if (md5(obj + SECRET) !== secret) {
                this.logout()
                window.location.href = "/login"
                return
            }
            const storage = JSON.parse(obj);
            Object.keys(storage).forEach(p => this[p] = storage[p]);

        } else {
            if (IsTrue(this.login)) {
                localStorage.removeItem(Global.USERINFO_KEY);
            }
            this.username = '未登录';
            this.avatar = '';
            this.token = '';
            this.roles = [];
            this.menu = [];
            this.button = [];
            this.codeMapping = {};
            this.pathMapping = {};
            this.login = false;
        }
    };

    logout() {
        localStorage.removeItem(Global.USERINFO_KEY);
        localStorage.removeItem(Global.SECRET_KEY);
    }

    storage(): boolean {
        if (IsTrue(this.login)) {
            loadMapping()
            const str = JSON.stringify(this);
            localStorage.setItem(Global.USERINFO_KEY, str);
            localStorage.setItem(Global.SECRET_KEY, md5(str + SECRET));
            return true;
        } else {
            localStorage.removeItem(Global.USERINFO_KEY);
            localStorage.removeItem(Global.SECRET_KEY);
            return false;
        }
    };

    hashRole(role: string): boolean {
        return role === undefined || this.roles.findIndex(p => p === role) !== -1;
    };

    hashMenuPath(menuPath: string): boolean {
        return this.pathMapping[menuPath] !== undefined;
    };

    hashMenuCode(menuCode: string): boolean {
        return menuCode === undefined || this.codeMapping[menuCode];
    };

    addLocalMenu(menuCode: []) {
        const _this = this;
        menuCode.forEach(p => {
            _this.menu.push(CopyMenuElement(RouterCodeMap[p]))
        })
    };

    getMenuList(): MenuElement[] {
        const menuList = [];
        for (let i in this.menu) {
            let menu = this.menu[i];
            const element: MenuElement = RouterCodeMap[menu.code];
            if (NotNull(element) && element.type === "Bar") {
                const adminMenu = CopyElement(element, menu.menuList?.map(p => p.code));
                adminMenu.name = menu.name;
                menuList.push(adminMenu);
            }
        }
        return menuList;
    };

}

function initMenu() {
    if (Global.InitMenu) {
        loginUser.menu = ROUTER_DATA.filter(p => p.type === "Bar");
        loginUser.button = ROUTER_DATA.filter(p => p.type !== "Bar");
    }
    loadMapping();
}


function loadMapping() {
    const set = new Set();
    const newCodeMapping = []
    const newPathMapping = []
    loadMenuElementMapping(set, loginUser.menu, newCodeMapping, newPathMapping)
    loadMenuElementMapping(set, loginUser.button, newCodeMapping, newPathMapping)
    loginUser.codeMapping = newCodeMapping;
    loginUser.pathMapping = newPathMapping;

}

function CopyMenuElement(element: MenuElement) {
    const menu: MenuElement = Copy(element, new MenuElement({}));
    if (element.menuList) {
        menu.menuList = element.menuList.map(p => CopyMenuElement(p));
    }
    return menu;
}

const loginUser: LoginUser = new LoginUser();
loginUser.reload();
initMenu();

export default loginUser;

