import {Layout, Menu, MenuProps, Spin} from 'antd';
import {MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons';
import React, {useEffect, useMemo, useState} from 'react';
import '../../components/layout/style.less';
import {IsNull, NotNull} from '@/config/util';
import Logo from '../logo';
import AccountOpt from "./account-opt";
import {MenuElement} from "@/config/router-parse";
import Global from "../../config/global";
import {TabsContent} from "./tabs-app";

type MenuItem = Required<MenuProps>['items'][number];


const LazyLoadSrc = (path) => {
    const Comp = React.lazy(() => import(`@src/${path}`));
    return (
        <React.Suspense fallback={<>   <Spin/></>}>
            <Comp/>
        </React.Suspense>
    );
};

const {Header, Content, Sider} = Layout;


function getItem(element: MenuElement, setSelectKey: (key: string)=>void): MenuItem {

    return {
        key: element.code,
        icon: NotNull(element.icon) ? LazyLoadSrc(element.icon) : undefined,
        children: element.menuList && element.menuList.length > 0 ? element.menuList.map(p => getItem(p, setSelectKey)) : undefined,
        label: <span
            onClick={() => {
                if (element.path !== "" && element.path !== "#") {
                    setSelectKey(element.code)
                }
            }}>{element.name}</span>,
    };
}


const homePage = Global.HomePage

export function AdminLayout(props) {
    const {codeMap, pathMap, loginUser} = props;
    const [collapsed, setCollapsed] = useState(false);
    const [selectKey, setSelectKey] = useState()
    const [openKeys, setOpenKeys] = useState()
    const menuList: MenuElement[] = useMemo(() => {
        return loginUser.getMenuList();
    }, [loginUser]);

    const items: MenuItem[] = useMemo(() => {
        const menus = []
        menuList.forEach(p => menus.push(getItem(p, setSelectKey)));
        return menus;
    }, [menuList]);

    useEffect(() => {
        const keys = [];
        let current = pathMap[window.location.pathname];
        setSelectKey(current.code)
        while (true) {
            if (!current.isPage()) {
                keys.push(current.code);
            }
            current = codeMap[current.parent];
            if (IsNull(current)) {
                break;
            }
        }
        setOpenKeys(keys)
    }, [ codeMap, pathMap]);

    const trigger = useMemo(() => {
        if (collapsed) {
            return <MenuUnfoldOutlined className="trigger" style={{padding: "12px"}}
                                       onClick={() => setCollapsed(!collapsed)}/>
        } else {
            return <MenuFoldOutlined className="trigger" style={{padding: "12px"}}
                                     onClick={() => setCollapsed(!collapsed)}/>
        }
    }, [collapsed]);


    return <>
        <Layout style={{minHeight: '100vh'}}>

            <Sider className={'ad'} collapsed={collapsed}
                   onCollapse={setCollapsed}>
                <Logo collapsed={collapsed} linkUrl={homePage}/>
                <Menu className={'adminLayout'} theme='dark' mode='inline'
                      items={items}
                      onSelect={(selectInfo) => {
                          setSelectKey(selectInfo.key)
                      }}
                      onOpenChange={(item) => {
                          let keys = [...openKeys]
                          keys[0] = item.length > 0 ? item[item.length - 1] : undefined;
                          setOpenKeys(keys)
                      }}
                      selectedKeys={[selectKey]}
                      openKeys={openKeys}
                />
            </Sider>

            <Layout className='site-layout'>
                <Header className='site-layout-background' style={{padding: 0, height: '6vh'}}>
                    {trigger}
                    <AccountOpt loginUser={loginUser}/>
                </Header>

                <Content color={'white'} style={{padding: '0 16px', height: '80vh'}}>
                    <TabsContent code={selectKey}
                                 onChange={(key) => setSelectKey(key)}>
                    </TabsContent>
                </Content>
            </Layout>
        </Layout>
    </>;
}

export default AdminLayout;
